import React, { useState, useEffect, useRef, useCallback } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Typography } from "@mui/material";
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from "prop-types";
import CloseIcon from '@mui/icons-material/Close';

import doc_vazio from '../resources/doc_vazio.png';
import doc_aprovado from '../resources/doc_aprovado.png';
import doc_negado from '../resources/doc_negado.png';
import doc_validando from '../resources/doc_validando.png';

import DialogFiles from '../components/DialogFiles.js';
import Loader from '../components/Loader.js';
import HomeService from "../services/home.service";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
	  backgroundColor: theme.palette.common.white,
	  color: 'rgba(0, 0, 0, 0.87)',
	  boxShadow: theme.shadows[1],
	  fontSize: 11,
	},
}));

const default_docs = [
	{
		Id: null,
		Status: null,
		TipoDocumento: 'Inscrição Municipal',
		Resumo: "",
		DtPrazo: null
	},
	{
		Id: null,
		Status: null,
		TipoDocumento: 'Certificado de Licenciamento Integrado',
		Resumo: "",
		DtPrazo: null
	},
	{
		Id: null,
		Status: null,
		TipoDocumento: 'Alvará Vigilância Sanitária',
		Resumo: "",
		DtPrazo: null
	},
	{
		Id: null,
		Status: null,
		TipoDocumento: 'Certificado de Licenciamento do Corpo de Bombeiros',
		Resumo: "",
		DtPrazo: null
	},
	{
		Id: null,
		Status: null,
		TipoDocumento: 'Alvará Licença Ambiental',
		Resumo: "",
		DtPrazo: null
	},
	{
		Id: null,
		Status: null,
		TipoDocumento: 'Alvará Publicitário',
		Resumo: "",
		DtPrazo: null
	},
];

const styles = {
	media: {
		position: "relative",
		height: "100%",
		width: "100%",
		backgroundColor: 'transparent',
		textAlign: 'center',
		alignItems: 'center',
	},
	card: {
		position: 'relative',
		backgroundColor: 'transparent',
		textAlign: 'center',
		alignItems: 'center',
	},
	overlay: {
		position: 'absolute',
		top: '130px',
		width: '80%',
		left: '10px',
		backgroundColor: 'transparent',
		textAlign: 'center',
		alignItems: 'center',
		fontSize: 14,
		fontWeight: 800,
		transform: 'translateY(-50%)'
	}
}

const Home = (props) => {
	const [loading, setLoading] = useState(false);
	const [selectedDoc, setSelectedDoc] = useState(undefined);
	const refDocs = useRef(null);
	const [dialogFilesOpen, setDialogFilesOpen] = useState(false);
	const [docsData, setDocsData] = useState([]);
	const [currentEmpresaId, setCurrentEmpresaId] = useState(null);
	const [selectedDocArquivos, setSelectedDocArquivos] = useState([]);

	const updateInfos = useCallback(async () => {
		var eId = HomeService.getCurrentEmpresa();
		if (eId !== undefined && eId !== null) 
		{
			var empresaData = props.currentUser?.usuarioEmpresas[currentEmpresaId];
			if (empresaData) 
			{
				//setLoading(true);
				HomeService.getDocsReg(empresaData.EmpId, empresaData.CnpjEmpresa).then(res => {
					if (res !== undefined && res !== null) 
					{
						for (let defaultDocData of default_docs) 
						{ 
							var find_doc = false;
							if (res !== undefined && res !== null) {
								for (let docData of res) {
									docData.DtPrazo = dayjs(docData.DtPrazo, "DD/MM/YYYY HH:mm:ss");
									if (docData.TipoDocumento === defaultDocData.TipoDocumento) {
										find_doc = true;
										break;
									}
								}

								if (!find_doc) {
									res.push(defaultDocData);
								}
							}
						}
						setDocsData(res);
					}
					
					setLoading(false);
				});
			}

			if (currentEmpresaId !== eId) {
				setCurrentEmpresaId(eId);
			}
		}
		}, [props.currentUser, currentEmpresaId])

	useEffect(() => {
		updateInfos();

		if (selectedDoc !== undefined && selectedDoc >= 0) {
			refDocs.current?.scrollIntoView({
				block: "start",
				inline: "nearest",
			});
		}
		  
		console.log("update Home");
	}, [selectedDoc, updateInfos]);

	const handleDeleteFile = (idxFile) => {
		if (selectedDoc !== undefined && selectedDoc >= 0) {
			var anexoFile = selectedDocArquivos[idxFile];
			if (anexoFile.Id !== null && anexoFile.Id !== undefined) {
				HomeService.deleteAnexoDoc(anexoFile.Id, docsData[selectedDoc].Id);
			}

			var tmp_docs = Object.assign([], selectedDocArquivos);
			tmp_docs.splice(idxFile, 1);
			setSelectedDocArquivos(tmp_docs);
		}
	};

	const handleAddFile = (files) => {
		if (selectedDoc !== undefined && selectedDoc >= 0 && files) 
		{
			var tmp_docs = Object.assign([], selectedDocArquivos);
			let formdata = new FormData();
			for (var idx = 0; idx < files.length; idx++) {
				let file = files[idx];
				tmp_docs.push({ NomeArquivo: file.name, LinkArquivo: null, Content: file, Id: null, KeyS3Arquivo: null });
				formdata.append('files[' + idx + ']', file);
			}

			if (docsData[selectedDoc].Id !== undefined && docsData[selectedDoc].Id !== null)
			{
				formdata.append('idDocumento', docsData[selectedDoc].Id);
				HomeService.addAnexoDoc(formdata);
			}

			setSelectedDocArquivos(tmp_docs);
		}
	};

	const handleDownloadFile = async (idxFile) => {
		try {
			const response = await axios.get(
				selectedDocArquivos[idxFile]?.LinkArquivo,
				{
					responseType: "blob",
				}
			);

			const pdfBlob = new Blob([response.data], { type: "application/pdf" });
			const url = window.URL.createObjectURL(pdfBlob);
			var filename = selectedDocArquivos[idxFile]?.NomeArquivo;
			const tempLink = document.createElement("a");
			tempLink.href = url;
			tempLink.setAttribute(
				"download",
				`${filename}`
			);

			document.body.appendChild(tempLink);
			tempLink.click();
			document.body.removeChild(tempLink);
			window.URL.revokeObjectURL(url);
		} 
		catch
		{

		}
	};

	const handleOpenDialogFiles = () => {
		setDialogFilesOpen(true);
	};

	const handleCloseDialogFiles = () => {
		setDialogFilesOpen(false);
	};

	const handleClickSim = () => {
		handleOpenDialogFiles();
	};

	const handleClickAvancar = () => {
		var empresaData = props.currentUser?.usuarioEmpresas[currentEmpresaId];
		if (selectedDoc !== undefined && empresaData) {
			if (docsData[selectedDoc].Id === null || docsData[selectedDoc].Id === undefined) {
				let formData = new FormData();
				if (selectedDocArquivos != null && selectedDocArquivos.length > 0) {
					for (var idx = 0; idx < selectedDocArquivos.length; idx++) {
						let file = selectedDocArquivos[idx];
						formData.append('files[' + idx + ']', file.Content);
					}
				}

				formData.append('idUsuario', props.currentUser.idUsuario);
				formData.append('idEmpresa', empresaData.EmpId);
				formData.append('idCnpjEmpresa', empresaData.IdCnpjEmpresa);
				formData.append('tipoDocumento', docsData[selectedDoc].TipoDocumento);
				if (docsData[selectedDoc].DtPrazo !== null) {
					formData.append('dataVencimento', docsData[selectedDoc].DtPrazo?.toJSON());
				}
				else
				{
					formData.append('dataVencimento', null);
				}
				formData.append('resumo', docsData[selectedDoc].Resumo);

				HomeService.addDocReg(formData).then((res) => {
					if (res === true) {
						//props.OpenSnackbar("Documento atualizado!");
						updateInfos();
					}
				});
			}
		}

		var tmpDoc = 0;

		if (selectedDoc === undefined) {
			tmpDoc = 0;
		}
		else if (selectedDoc < docsData.length-1) {
			tmpDoc = selectedDoc + 1;
		} 
		else 
		{
			tmpDoc = undefined;
		}

		handleSetSelectedDoc(tmpDoc);
	};

	const handleClickVoltar = () => {
		var tmpDoc = 0;

		if (selectedDoc === undefined) {
			tmpDoc = 0;
		}
		else if (selectedDoc === 0) {
			tmpDoc = undefined;
		}
		else if (selectedDoc > 0) {
			tmpDoc = selectedDoc - 1;
		}

		handleSetSelectedDoc(tmpDoc);
	};

	const handleClickFechar = () => {
		handleSetSelectedDoc(undefined);
	};

	const handleSetDataVencimento = (data) => {
		if (selectedDoc !== undefined && selectedDoc !== null) {
			var tmp_docs = Object.assign([], docsData);
			tmp_docs[selectedDoc].DtPrazo = dayjs(data);
			if (docsData[selectedDoc].Id !== null && docsData[selectedDoc].Id !== undefined) {
				HomeService.updateDocReg(props.currentUser.idUsuario, docsData[selectedDoc].Id, tmp_docs[selectedDoc].DtPrazo.toJSON(), null);
			}
			setDocsData(tmp_docs);
			console.log("handleSetDataVencimento", tmp_docs);
		}
	};

	const handleSetResumo = (resumo) => {
		if (selectedDoc !== undefined && selectedDoc !== null) {
			var tmp_docs = Object.assign([], docsData);
			tmp_docs[selectedDoc].Resumo = resumo;
			if (docsData[selectedDoc].Id !== null && docsData[selectedDoc].Id !== undefined) {
				HomeService.updateDocReg(props.currentUser.idUsuario, docsData[selectedDoc].Id, null, tmp_docs[selectedDoc].Resumo);
			}
			setDocsData(tmp_docs);
			console.log("handleSetResumo", tmp_docs);
		}
	};

	const handleSetSelectedDoc = (idx) => {
		if (idx !== undefined && idx >= 0) 
		{
			if (docsData[idx]?.Id !== undefined && docsData[idx]?.Id !== null) 
			{
				HomeService.getAnexosDoc(docsData[idx].Id).then((res) => {
					if (res && res.length > 0) 
					{
						var tmp_anexos = [];
						for (let anexoData of res) {
							tmp_anexos.push({ NomeArquivo: anexoData.NomeArquivo, LinkArquivo: anexoData.LinkArquivo, Id: anexoData.Id });
						}
						setSelectedDocArquivos(tmp_anexos);
					}
					else 
					{
						setSelectedDocArquivos([]);
					}
				});
			}
			else 
			{
				setSelectedDocArquivos([]);
			}
		}

		setSelectedDoc(idx);
	};

	const handleClickNao = () => {
		setSelectedDocArquivos([]);
		handleSetDataVencimento(undefined);
	};

	let elements_docs = [];
	if (docsData !== undefined && docsData !== null && docsData.length > 0) 
	{
		for (let [idx, docData] of docsData.entries()) {
			let img, color;
			if (docData?.Status === "Validando") {
				img = doc_validando;
				color = 'warning.main';
			}
			else if (docData?.Status === "Negado") {
				img = doc_negado;
				color = 'error.main';
			}
			else if (docData?.Status === "Aprovado") {
				img = doc_aprovado;
				color = 'success.main';
			}
			else 
			{
				img = doc_vazio;
				color = 'none.main';
			}

			elements_docs.push(
				<Grid item key={idx} xs={6} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} onClick={() => handleSetSelectedDoc(idx)}>
					<LightTooltip disableFocusListener disableTouchListener title={docData?.Status}>
						<Card style={styles.card} sx={{ boxShadow: 0 }}>
							<CardActionArea>
								<CardMedia image={img} component="img" />
								<Typography style={styles.overlay} sx={{ color: color }}>
									{docData?.TipoDocumento}
								</Typography>
							</CardActionArea>
						</Card>
					</LightTooltip>
				</Grid>
			);
		}
	}

	return (
		<React.Fragment>
			<DialogFiles 
				open={dialogFilesOpen} 
				close={handleCloseDialogFiles} 
				handleDeleteFile={handleDeleteFile} 
				handleAddFile={handleAddFile} 
				handleDownloadFile={handleDownloadFile} 
				anexos={selectedDocArquivos} 
			/>

			<Container component="main" maxWidth="xs" sx={{ backgroundColor: 'transparent', mt: '8vmin' }}>
				{elements_docs.length > 0 && loading === false ?
					<Grid container justifyContent="center" alignItems="center" spacing={4}>
						{elements_docs}
					</Grid>
					:
					<Grid container justifyContent="center" alignItems="center" spacing={0}>
						<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
							<Loader/>
						</Grid>
					</Grid>
				}
			</Container>

			<Container maxWidth="sm" sx={{ mt: '15vmin', mb: '15vmin', width: '100%' }} ref={refDocs}>
				{selectedDoc !== undefined &&
					<Card sx={{ width: '100%' }} elevation={1}>
						<CardContent>
							<Grid container justifyContent="center" alignItems="center" spacing={3}>
								<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
									<Stack alignItems="center" direction="row" gap={2}>
										<IconButton fill="primary.main" aria-label="voltar" onClick={handleClickVoltar}>
											<KeyboardReturnOutlinedIcon />
										</IconButton>
										<Typography variant="subtitle1" sx={{ color: 'common', fontWeight: '800' }}>
											A sua unidade possui o documento: &quot;{docsData[selectedDoc]?.TipoDocumento}&quot; ?
										</Typography>
										<IconButton fill="primary.main" aria-label="voltar" onClick={handleClickFechar}>
											<CloseIcon />
										</IconButton>
									</Stack>
								</Grid>
								<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mt: '2vmin' }}>
									<Button variant="outlined" selected={selectedDocArquivos?.length > 0} onClick={handleClickSim} disableElevation sx={{
										padding: "5px",
										width: '70%',
										"&:hover": {
											backgroundColor: "success.main"
										}
									}} fullWidth>
										SIM
									</Button>
								</Grid>
								{selectedDocArquivos?.length > 0 && 
									<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mb: '2vmin' }}>
										
										<Grid container justifyContent="center" alignItems="center" spacing={1}>
											<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
												<Typography variant="subtitle1" sx={{ m: 0, p: 0, fontWeight: '600' }}>Data de vencimento:</Typography>
											</Grid>
											<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
												<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pt-br"}>
													<DatePicker 
														format="DD/MM/YYYY"
														required
														value={(docsData[selectedDoc]?.DtPrazo !== null && docsData[selectedDoc]?.DtPrazo !== undefined) ? docsData[selectedDoc]?.DtPrazo : null}
														onChange={(newValue) => handleSetDataVencimento(newValue)}
													/>
												</LocalizationProvider>
											</Grid>
										</Grid>
										
									</Grid>
								}
								<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
									<Button variant="outlined" selected={selectedDocArquivos?.length === 0} disableElevation sx={{
										padding: "5px",
										width: '70%',
										"&:hover": {
											backgroundColor: "primary.main"
										}
									}} fullWidth onClick={() => handleClickNao()}>
										NÃO
									</Button>
								</Grid>
								<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mt: '2vmin' }}>
									<TextField
										fullWidth
										placeholder="Adicionar comentários, se necessário"
										multiline
										minRows={6}
										variant="filled"
										sx={{ width: '70%' }}
										value={(docsData[selectedDoc]?.Resumo !== null && docsData[selectedDoc]?.Resumo !== undefined) ? docsData[selectedDoc]?.Resumo : ""}
										onChange={(event) => handleSetResumo(event.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mt: '10vmin' }}>
									<Button variant="contained" disableElevation sx={{
										backgroundColor: "primary.main",
										color: "secondary.main",
										padding: "5px",
										width: '70%',
										"&:hover": {
											backgroundColor: "primary.hover"
										}
									}} fullWidth onClick={handleClickAvancar}>
										{selectedDoc && selectedDoc + 1 === docsData.length ?
											"CONCLUIR"
											:
											"AVANÇAR"
										}
									</Button>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				}
			</Container>

		</React.Fragment>
	);
};

Home.propTypes = {
    OpenSnackbar: PropTypes.func,
	currentUser: PropTypes.object,
};

export default Home;

