import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate  } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import HomeService from "../services/home.service";

function AppNavBar(props) {
	const navigate = useNavigate();
	const [anchorElUser, setAnchorElUser] = useState(null);
	const [currentEmpresaId, setCurrentEmpresaId] = useState(0);

	useEffect(() => {
		var eId = HomeService.getCurrentEmpresa();
		if (eId === undefined || eId === null) {
			eId = 0;
			HomeService.setCurrentEmpresa(eId);
		}
		if (currentEmpresaId !== eId) {
			setCurrentEmpresaId(eId);
		}
		console.log("update AppNavBar");
	}, [currentEmpresaId]);

	const handleOpenSettingsMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseSettingsMenu = () => {
		setAnchorElUser(null);
	};

	const handleClickProfile = () => {
		navigate('/profile');
	};

	const handleClickLogout = () => {
		props.logOut();
	};

	const handleClickHome = () => {
		navigate('/');
	};

	const handleSetCurrentEmpresa = (empresaId) => {
		HomeService.setCurrentEmpresa(empresaId);
	};

	let elements_empresas = [];
	if (props.currentUser !== undefined && props.currentUser.usuarioEmpresas?.length > 0) {
		for (let [idx, empresaData] of props.currentUser.usuarioEmpresas.entries()) 
		{ 
			elements_empresas.push(
				<MenuItem key={"empresa"+idx} value={idx}>{empresaData.NomeEmpresa}</MenuItem>
			);
		}
	}

	return (
		<AppBar position="static" color="transparent" elevation={0}>
			<Toolbar>
				<Box sx={{ flexWrap: 'wrap', flexGrow: 1, display: 'flex' }}>
				</Box>
				<Box sx={{ flexGrow: 0, mr: 1 }} onClick={handleClickHome}>
					<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
						<Select
							value={currentEmpresaId}
							onChange={(event) => handleSetCurrentEmpresa(event.target.value)}
						>
							{elements_empresas}
						</Select>
					</FormControl>
				</Box>
				<Box sx={{ flexGrow: 0 }}>
					<Tooltip title="Open Account Menu">
						<IconButton onClick={handleOpenSettingsMenu} sx={{ p: 0 }}>
							<Avatar alt="Avatar" />
						</IconButton>
					</Tooltip>
					<Menu
						sx={{ mt: '55px' }}
						id="menu-appbar"
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseSettingsMenu}
					>
						<MenuItem onClick={handleClickProfile}>
							<Typography textAlign="center">Perfil</Typography>
						</MenuItem>
						<MenuItem onClick={handleClickLogout}>
							<Typography textAlign="center">Sair</Typography>
						</MenuItem>
					</Menu>
				</Box>
			</Toolbar>
		</AppBar>
	);
}

AppNavBar.propTypes = {
    currentUser: PropTypes.object,
    logOut: PropTypes.func,
};

export default AppNavBar;